import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useFetchItems from "../../hooks/useFetchItems";
import AppServices from "../../services/general/AppServices";
import MobMenu from "./MobMenu";
import SearchMenu from "./SearchMenu";

const Menu = ({item}) => {
    const [searchEnabled, setSearchEnabled] = useState();

    const { 
        items, 
        loading, 
        params, 
        handlePageClick, 
        setFilterData 
    } = useFetchItems(AppServices.menu, "main-menu");

    useEffect(() => {
        setSearchEnabled(item?.find(it => it.type === "search_enabled")?.value)
    }, [item])


  return (
    <div id="sns_menu">
      <div className="container">
        <div className="sns_mainmenu">
          <div id="sns_mainnav">
            <div id="sns_custommenu" className="visible-md visible-lg">
              <ul className="mainnav">
                {items && 
                  items.map((item, index)=>
                    <li className="level0 nav-3 no-group drop-submenu12 custom-itemdrop-staticblock" key={index}>
                      <NavLink className=" menu-title-lv0" to={item.value}>
                        <span className="title">{item.title}</span>
                      </NavLink>
                      {item.items &&
                        <div className="wrap_dropdown">
                          <div className="row">
                            {item.items?.map((elem, index) =>
                              elem.items ? 
                                <div className="col-sm-3" key={index}>
                                  <h6 className="title menu1-2-5">
                                    <NavLink to={elem.value} target={elem.type}>
                                      {elem.title}
                                    </NavLink>
                                  </h6>
                                  <ul className="level1">
                                    {elem.items.map((it, index) =>
                                      <li className="level2 nav-1-3-16 first" key={index}>
                                        <NavLink className=" menu-title-lv2" to={it.value} target={it.type}>
                                          <span className="title">
                                            {it.title}
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                                :
                                item.title === items[0]?.title
                                  ?
                                  <div className="col-sm-3" key={index}>
                                    <h6 className="title menu1-2-5">
                                      <NavLink to={elem.value} target={elem.type}>
                                        {elem.title}
                                      </NavLink>
                                    </h6>
                                  </div>
                                  :
                                  <div className="wrap_submenu" key={index}>
                                    <ul className="level0">
                                        <li className="level1 nav-1-1 first mrb10">
                                            <NavLink to={elem.value} className="menu-title-lv1" target={elem.type}>
                                                <span className="title">{elem.title}</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                  </div>
                            )}
                          </div>
                        </div>
                      }
                    </li>
                  )}
              </ul>
            </div>
            <MobMenu items={items}/>
          </div>
          {searchEnabled && <SearchMenu/>}
        </div>
      </div>
    </div>
  );
};

export default Menu;
