import React from "react";
import HeaderLogo from "../simple/HeaderLogo";
import HeaderTop from "../simple/HeaderTop";
import Menu from "./Menu";

const Header = ({item}) => {
  return (
    <div id="sns_header" className="wrap">
      <HeaderTop item={item}/>
      <HeaderLogo item={item}/>
      <Menu item={item}/>
    </div>
  );
};

export default Header;
