import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';

const LanguagesDropdown = () => {
    const {lang, changeLanguage, languages} = useLangContext();

  return (
    <div className="module-setting">
        <div className="mysetting language-switcher">
            <div className="tongle">
                <span>{lang}</span>
            </div>
            <div className="content">
                <div className="language-switcher">
                    <ul id="select-language">
                        {languages?.map((lang, index) =>
                            <li key={index} onClick={() => changeLanguage(lang.slug)}>
                                <div>
                                    <span>{lang.title}</span>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LanguagesDropdown