import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { NavLink } from 'react-router-dom';
import Video from '../simple/Video';
import useWindowResize from '../../hooks/useWindowResize.hook';

const MainOffer = ({item}) => {
    const [slides, setSlides] = useState();
    const [banners1, setBanners1] = useState();
    const [banner2, setBanner2] = useState();
    const [mobSlides, setMobSlides] = useState();
    const {width} = useWindowResize();

    useEffect(() => {
        if(item){
            setSlides(item.elements?.find(item => item.type == 'slider').elements);
            setBanners1(item.elements?.filter(item => item.type == 'banner-1-col'));
            setBanner2(item.elements?.find(item => item.type == 'banner-2-col'));
            setMobSlides([...item.elements?.find(item => item.type == 'slider').elements, ...item.elements?.filter(item => item.type == 'banner-1-col')])
        }
    }, [item])

    const options = {
        nav: false,
        dots: true,
        loop: true,
        responsive:{
            0:{
                items:1
            }
        }
    }

    useEffect(() => {
        if(width <= 912){

        }
    }, [width])
  return (

    <div className='container'>
        <div className='main-offer'>
            <div className='main-offer-slider'>
                {width > 912 ?
                    slides && 
                    <OwlCarousel className="owl-carousel owl-theme" style={{overflow: "hidden"}} {...options}>
                            {slides.map((slide, index) => 
                                <NavLink key={index} to={slide.link}>
                                    <div className="item">
                                        <img src={slide.image} alt=""/>
                                        <div className='item-text'>
                                            <div className='item-title'>{slide.title}</div>
                                            <div>{slide.subtitle}</div>
                                        </div>
                                    </div>  
                                </NavLink>
                            )}
                    </OwlCarousel>
                    :
                    <></>
                }
                {width <= 912 ?
                    mobSlides && <OwlCarousel className="owl-carousel owl-theme" style={{overflow: "hidden"}} {...options}>
                            {mobSlides.map((slide, index) => 
                                <NavLink key={index} to={slide.link}>
                                    <div className="item">
                                        <img src={slide.image} alt=""/>
                                        <div className='item-text'>
                                            <div className='item-title'>{slide.title}</div>
                                            <div>{slide.subtitle}</div>
                                        </div>
                                    </div>  
                                </NavLink>
                            )}
                    </OwlCarousel>
                    :
                    <></>
                }
            </div>
            <div className='flex'>
                <div className='grid'>
                    {banners1 && banners1.map((banner, index) => 
                        <div key={index}>
                            <NavLink to={banner.link}>
                                <img src={banner.image} alt=""/>
                            </NavLink>
                        </div>
                    )}
                </div>
                {banner2 &&
                    <div>
                        <Video banner2={banner2}/>
                    </div>
                }
            </div>
        </div>
    </div>
  )
}

export default MainOffer