import React from "react";
import OwlCarousel from 'react-owl-carousel';
import useFetchItems from "../../hooks/useFetchItems";
import PartnersServices from "../../services/general/PartnersServices";

const Partners = ({item, title}) => {
    const { 
        items, 
        loading, 
        pageCount, 
        params, 
        handlePageClick, 
        setFilterData 
    } = useFetchItems(PartnersServices.index);

    const options = {
        nav: true,
        dots: false,
        loop: false,
        responsive:{
            0:{
                items:2
            },
            480:{
                items:3
            },
            600:{
                items:4
            },
            980:{
                items:5
            },
            1000:{
                items:6
            }
        }
    }

  return (
    items?.items?.length >= 1 &&
    <div id="sns_partners" className="wrap">
      <div className="container">
        <div className="block-title">
            <h3>{title ? title : item?.title}</h3>
        </div>
        <div className="slider-wrap">
          <div className="partners_slider_in">
                <OwlCarousel
                id="partners_slider1"
                className="our_partners owl-carousel owl-theme owl-loaded"
                style={{display: "inline-block"}}
                {...options}
                >
                    {items.items.map((item, index) => 
                        <div className="item" key={index}>
                            <a className="banner11" href={item.link} target="_blank">
                                <img alt={item.title} src={item.image} />
                            </a>
                        </div>
                    )}
                </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
