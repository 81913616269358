import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import img1 from "../../images/placeholder.png"
import Fancybox from './FancyBox';

const Slider = ({gallery}) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
        <div className="product-img-box col-md-4 col-sm-5">
            <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
            >
                <Swiper
                    loop={true}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                >
                    {gallery?.map((elem, index) => 
                        <SwiperSlide key={index} className='product-slide'>
                            <a href={elem} data-width="700" data-height="700" className="product-image__body" target="_blank" data-fancybox="gallery">
                                <img alt="" src={elem ? elem : img1} 
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img1
                                    }}
                                /> 
                            </a>
                        </SwiperSlide>
                    )}
                </Swiper>
            </Fancybox>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >
                {gallery?.map((elem, index) => 
                    <SwiperSlide key={index} className='product-slide2'>
                        <img alt="" src={elem ? elem : img1} 
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img1
                                    }}
                                /> 
                    </SwiperSlide>
                )}
            </Swiper>
        </div>          
    </>
  )
}

export default Slider
