import React, { useEffect, useState } from 'react'
import ModalCustom from './ModalCustom';
import RelatedProducts from './RelatedProducts';
import Slider from './Slider';
import UpsellProducts from './UpsellProducts';
import { useLangContext } from '../../i18n/ProvideLang';
import TabProductPane from './TabProductPane';
import Utils from '../../services/utils';

const ProductOptions = ({items}) => {
    const [show, setShow] = useState(false);
    const [image, setImage] = useState();
    const [gallery, setGallery] = useState();
    const [features, setFeatures] = useState();
    const [tabs, settabs] = useState();

    const {localizationsItems} = useLangContext();

    useEffect(() => {
        if(items){
            setFeatures(items.features)
            setGallery(items.gallery)
            setImage(items.image)
            settabs(items.elements?.find(it => it.type === "tab-component")?.elements)
        }
    }, [items])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


  return (
    <div id="sns_content" className="wrap layout-m">
      <div className="container">
        <div className="row">
          <div id="sns_main" className="col-md-12 col-main">
            <div id="sns_mainmidle">
              <div className="product-view sns-product-detail">
                <div className="product-essential clearfix">
                  <div className="row row-img">
                    {gallery && <Slider gallery={gallery}/>}
                    <div
                      id="product_shop"
                      className="product-shop col-md-8 col-sm-7"
                    >
                      <div className="item-inner product_list_style">
                        <div className="item-info">
                          <div className="item-title">
                            {items?.title}
                          </div>
                          <div className="item-price">
                            <div className="price-box">
                              <span className="regular-price">
                                {items.price ?
                                    <span className="price">{Utils.numberWithSpaces(items.price)} {localizationsItems?.currency}</span>
                                    :
                                    <span className="price">{localizationsItems?.not_specified}</span>
                                }
                                <span className="price">{items?.subtitle}</span>
                              </span>
                            </div>
                          </div>                                                 
                          <div className="product-item">
                            <div className='row'>
                              {features?.map((item, index) => 
                                <div className='col-md-6' key={index}>
                                  <h5>{item.title}</h5>
                                  <p>
                                      {item.value}
                                  </p>
                              </div>
                              )}
                            </div>
                          </div>
                          <div className="desc std">
                            <button className='black' onClick={() => handleShow()}>{localizationsItems?.product_application}</button>
                            {/*<button className='outline'>Предварительный расчет</button>*/}
                          </div>
                          <div className="addthis_native_toolbox"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom row">
            <div className="2coloum-left">
                <div id="sns_mainm" className="col-md-12">
                    <div id="sns_description" className="description">
                        {tabs ?
                            <div className="sns_producttaps_wraps1">
                                <TabProductPane item={tabs}/>
                            </div>
                            :
                            <div className="sns_producttaps_wraps1">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li role="presentation" className="active style-detail">
                                    <div>
                                        {localizationsItems?.about_product}
                                    </div>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div role="tabpanel" className="tab-pane active" id="home">
                                    <div className="style1">
                                        {items && <p dangerouslySetInnerHTML={{ __html: items.description }}></p>}
                                    </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
              <UpsellProducts/>
              <RelatedProducts/>
            </div>
          </div>
        </div>
      </div>
      <ModalCustom show={show} handleClose={handleClose} productName={items?.title}/>
    </div>
  )
}

export default ProductOptions