import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import Fancybox from './FancyBox';

const Gallery = ({item}) => {
    const options = {
        autoplayHoverPause: true,
        autoplaySpeed: 2000,
        autoplay: true,
        loop: false,
        dots: false,
        nav: true,
        navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        }
    }

  return (
    <section className="ptb-70">
            <div className="default-section-title default-section-title-middle">
                <h3>{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </div>
            <div className="section-content">
                {item.is_slider ?
                    <div>
                        {item.elements && 
                            <Fancybox
                                options={{
                                    Carousel: {
                                    infinite: false,
                                    },
                                }}
                            >
                                <OwlCarousel className='gallery-slider owl-carousel gallery-slider' {...options}>
                                    {item?.elements.map((image, index) =>
                                        <a href={image} data-width="700" data-height="700" key={index} className="product-image__body" target="_blank" data-fancybox="gallery">
                                            <img className="product-image__img" src={image} alt=""/>
                                        </a>
                                    )}
                                </OwlCarousel>
                            </Fancybox>
                        }
                    </div>
                    :
                    item &&
                        <Fancybox
                            options={{
                                Carousel: {
                                    infinite: false,
                                },
                            }}
                        >
                            <div className="row justify-content-center">
                                {item.elements.map((image, index) =>
                                    <div className="col-lg-4 col-6 mb-3" key={index} >
                                        <a href={image} target="_blank" data-fancybox="gallery">
                                            <img src={image} alt="" className='gallery-img'/>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </Fancybox>
                }
            </div>
    </section>
  )
}

export default Gallery