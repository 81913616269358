import React from 'react'

const Description = ({item}) => {
  return (
    <section className="point-blocks">
        <div className="container description">
            <h3>{item?.title}</h3>
            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
        </div>
    </section>
  )
}

export default Description