import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

const MobMenuSub = ({elem, active, setActive}) => {
    const [show2, setShow2]= useState();

  return (
    <li className="level1 nav-5-1 first parent">
        <div className="accr_header">
            <NavLink className=" menu-title-lv1" to={elem.value} onClick={() => setActive(false)}>
                <span>{elem.title}</span>
            </NavLink>
            {elem.items && 
                <span className="btn_accor" onClick={() => setShow2(!show2)}>
                    <i className={show2 ? "fa fa-minus" : "fa fa-plus"}></i>
                </span>
            }
        </div>
        <div className="accr_content" style={{display: show2 ? "block" : "none"}}>
            <ul className="level1">
                {elem.items?.map((it, index) => 
                    <li className="level2 nav-5-1 first" key={index}>
                        <div className="accr_header">
                            <NavLink className=" menu-title-lv2" to={it.value} onClick={() => setActive(false)}>
                                <span>{it.title}</span>
                            </NavLink>
                        </div>
                    </li>
                )}
            </ul>
        </div>
    </li>
  )
}

export default MobMenuSub