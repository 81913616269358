import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Advantages from "../../components/complex/Advantages";
import Breadcrumbs from "../../components/complex/Breadcrumbs";
import NewsGrid from "../../components/complex/NewsGrid";
import NewsGrid2 from "../../components/complex/NewsGrid2";
import Partners from "../../components/complex/Partners";
import PointsBlock from "../../components/complex/PointsBlock";
import ProductOptions from "../../components/complex/ProductOptions";
import useFetchItems from "../../hooks/useFetchItems";
import CatalogServices from "../../services/general/CatalogServices";
import { useLangContext } from "../../i18n/ProvideLang";

const ProductPage = () => {
    const {id} = useParams();
    const {lang, localizationsItems} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();
    
    const { 
      items, 
      loading, 
      pageCount, 
      params, 
      handlePageClick, 
      setFilterData 
    } = useFetchItems(CatalogServices.products.view, id);

  return (
    <>
      <Helmet>
        <title>{items?.title}</title>
      </Helmet>
      {loading ?
        <div className="preloader">
          <div className="isLoading"></div>
        </div>
        :
        <>
          <Breadcrumbs title={items?.title}/>
          {items && <ProductOptions items={items}/>}
          <NewsGrid title={localizationsItems?.our_works}/>
          <Partners title={localizationsItems?.partners}/>
          <NewsGrid2 title={localizationsItems?.blog}/>
        </>
      }
      {/*<PointsBlock/>*/}
    </>
  );
};

export default ProductPage;
