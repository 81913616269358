import React, { useEffect, useState } from "react";
import useFetchItems from "../../hooks/useFetchItems";
import AppServices from "../../services/general/AppServices";
import { NavLink } from "react-router-dom";
import LanguagesDropdown from "./LanguagesDropdown";

const HeaderTop = ({item}) => {
    const [socials, setSocials] = useState();
    const [languagesVisible, setLanguagesVisible] = useState();

    const { 
        items, 
        loading, 
        params, 
        handlePageClick, 
        setFilterData 
    } = useFetchItems(AppServices.menu, "top-menu");

    useEffect(() => {
        if(item){
            setSocials(item.find(it => it.type === "socials")?.value)
            setLanguagesVisible(item.find(it => it.type === "languages-enabled")?.value)
        }
    }, [item])

  return (
    <div className="sns_header_top">
        <div className="container">
            <div className="sns_module">
                <div>
                    <div className="header-setting">
                        {languagesVisible && <LanguagesDropdown/>}
                    </div>
                    {socials &&
                        <ul className="header-socials">
                            {socials.map((it, index) =>
                                <li key={index}>
                                    <NavLink to={it.value} target="_blank">
                                        <i className={
                                                    it.type === "instagram" ? "fa fa-instagram"
                                                    :
                                                    it.type === "facebook" ? "fa fa-facebook"
                                                    :
                                                    it.type === "twitter" ? "fa fa-twitter"
                                                    :
                                                    it.type === "whatsapp" ? "fa fa-whatsapp"
                                                    :
                                                    "fa fa-instagram"
                                                    }
                                        ></i>  
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    }
                    </div>
                <div className="header-account">
                    <div className="myaccount">
                        <div className="tongle">
                            <span>{items && items[0].title}</span>
                            <i className="fa fa-angle-down"></i>
                        </div>
                        <div className="customer-ct content">
                            <ul className="links">
                                {items && items.map((item, index) =>
                                    <li key={index} className="links-item">       
                                        <NavLink to={item.value} target={item.type}>
                                            {item.title}
                                        </NavLink>    
                                        {item.items && <div className="links-sub">
                                            {item.items?.map((el, index) =>
                                                <NavLink to={el.value} className="links-sub-item" key={index}>{el.title}</NavLink>
                                            )}
                                        </div> }  
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default HeaderTop;
