import React from 'react'
import Utils from '../../services/utils'
import img1 from "../../images/placeholder.png"

const NewsCard2 = ({item, data, ...props}) => {
  return (
    <div className="item" {...props}>
        <div className={data ? "banner5 banner-service" : "banner5"}>
            <img alt="" src={item.image ? item.image : img1} 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img1
                                }}
                        /> 
        </div>
        <div className="blog-page">
            <div className="blog-right">
                <div className="style1">
                    {!data && <p dangerouslySetInnerHTML={{ __html: Utils.generateDate(item.date) }}></p>}
                </div>
                <p className="style2">{item.title}</p>
                <p className="style3">
                    {item.description}
                </p>
            </div>
        </div>
    </div>
  )
}

export default NewsCard2