import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Checkbox from "../simple/Checkbox";

const Filter = ({params, setFilterData, categories, setCategories, title, productId}) => {
  const [checkedItems, setCheckedsItems] = useState([]);
  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if(params.get('categories[]')){
      setCheckedsItems([query.get('categories[]')])
    }
  }, [query.get('categories[]')])

  const changeFilter = (title, checked) => {
    if(checked){
      let str = "";
      setCheckedsItems([...checkedItems, title]);
      if(checkedItems.length){
        for(let i =0; i<checkedItems.length; i++){
          str+=`categories[]=${checkedItems[i]}&`
        }
        str +=`categories[]=${title}`
      }else{
        str=`categories[]=${title}`
      }
      setFilterData(str)
    }else{
      let arr = checkedItems.filter(item => item !== title);
      setCheckedsItems(arr)
      let str = "";
      if(arr.length){
        for(let i = 0; i<arr.length; i++){
          if(i === arr.length-1){
            str+=`categories[]=${arr[i]}`
          }else{
            str+=`categories[]=${arr[i]}&`
          }
        }
      }else{
        str = ''
      }
      if(str){
        setFilterData(str)
      }else{
        setFilterData(``)
      }
    }
  }



  return (
    <div className="block block-layered-nav block-layered-nav--no-filters">
      <div className="block-title">
        <strong>
          <span>{title}</span>
        </strong>
      </div>
      <div className="block-content toggle-content">
        <dl id="narrow-by-list">
          <dt className="odd">{categories?.title}</dt>
          <dd className="odd odd2">
            <ol>
              {categories?.elements?.map((category, index) =>
                <Checkbox category={category} changeFilter={changeFilter} checkedItems={checkedItems} key={index}/>
              )}
            </ol>
          </dd>
        </dl>
      </div>
    </div>
  );
};

export default Filter;
