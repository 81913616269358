export const MAIN_PAGE_ROUTE = '/:lang'
export const CATEGORY_PAGE_ROUTE = '/:lang/catalog/:id'
export const CATALOG_PAGE_ROUTE = '/:lang/catalog'
export const PRODUCT_DETAIL_PAGE_ROUTE = '/:lang/products/:id'
export const ARTICLE_DETAIL_PAGE_ROUTE = '/:lang/articles/:id'
export const PROJECT_DETAIL_PAGE_ROUTE = '/:lang/projects/:id'
export const SAMPLE_PAGE_ROUTE = '/:lang/page/:id'
export const ERROR_PAGE_ROUTE = '/:lang/error'

export const ARTICLE_PAGE_ROUTE = '/:lang/articles'
export const PROJECTS_PAGE_ROUTE = '/:lang/projects'
export const SERVICES_PAGE_ROUTE = '/:lang/services'
export const SERVICES_DETAIL_PAGE_ROUTE = '/:lang/services/:id'