import http from "./http-common"
import {getData, postData} from "../handlers";

export default class AppServices {
    static settings = () => {
        return getData(http, `/app/settings`);
    }

    static localizations = () => {
        return getData(http, `/app/localizations`);
    }

    
    static menu = (menuName, queryString) => {
        return getData(http, `/app/menu/${menuName}`);
    }

    static page = (pageName) => {
        return getData(http, `/app/page/${pageName}`);
    }

    static request = (data) => {
        return postData(http, `/app/request/contact`, data)
    }

    static requestTemplate = (data) => {
        return postData(http, `/app/request/template`, data)
    }
}