import React from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../../images/placeholder.png"

const NewsCard = ({item, ...props}) => {
  const navigate = useNavigate();

  const generateDate = (data) => {
    const month_ru = ["Янв", "Фев", "Март","Апр","Май","Июнь","Июль","Авг","Сент","Окт","Нояб","Дек"];
    const month_kk = ["Қаңтар", "Ақпан", "Наурыз","Сәуір","Мамыр","Маусым","Шілде","Тамыз","Қыркүйек","Қазан","Қараша","Желтоксан"]

    const date = new Date(data);
    const count = date.getMonth()
    let month = month_ru[count];

    let dd = {
      day: date.getUTCDate(),
      mon: month
    }
    return dd
  }

  return (
    <div className="item" {...props}>
        <div className="banner5">
            <img alt="" src={item.image ? item.image : img1} 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img1
                                }}
                        /> 
        </div>
        <div className="blog-page">
            <div className="blog-right">
            <div className="style1">
                <p>{item.location}</p>
            </div>
            <p className="style2">{item.title}</p>
            <p className="style3">
                {item.description}
            </p>
            </div>
        </div>
    </div>
  );
};

export default NewsCard;
