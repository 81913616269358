import React from 'react'
import Breadcrumbs from '../../components/complex/Breadcrumbs'
import Error from '../../components/complex/Error'
import Footer from '../../components/complex/Footer'
import Header from '../../components/complex/Header'

const ErrorPage = () => {
  return (
    <div className='sns-404'>
        <Breadcrumbs title={"Ошибка"}/>
        <Error/>
    </div>
  )
}

export default ErrorPage