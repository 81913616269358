import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import Article from '../../components/complex/Article'
import Breadcrumbs from '../../components/complex/Breadcrumbs'
import useFetchItems from '../../hooks/useFetchItems'
import ArticleServices from '../../services/general/ArticleServices'
import { useLangContext } from '../../i18n/ProvideLang'
import useFetchInfo from '../../hooks/useFetchInfo.hook'

const BlogDetail = () => {
    const {id} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();

    useEffect(() => {
        if(lang){
          navigate(`/${lang}/articles/${id}`)
        }
    }, [lang])
    
    const {info, loading} = useFetchInfo(ArticleServices.view, id, null, lang);

  return (
    <>
        <Helmet>
            <title>{info?.title}</title>
        </Helmet>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
        :
        <>
            <Breadcrumbs title={info?.title}/>
            <div className="blog-pagev1 detail cms-simen-home-page-v2 default cmspage">
                {info && <Article item={info}/>}
            </div>
        </>}
    </>
  )
}

export default BlogDetail