import React, { useEffect, useState } from 'react'

const Checkbox = ({category, changeFilter, checkedItems}) => {
    const [checked, setChecked] = useState();

    useEffect(() => {
        setChecked(checkedItems.find(item => item === category.value) ? true : false)
    }, [checkedItems])

    const changeItem = (e, title) => {
        setChecked(!checked); 
        changeFilter(title, !checked)
    }

  return (
    <li>
        <label onClick={(e) => changeItem(e, category.value)} className='filter-checkbox-label'>
            {/*<input type="checkbox" onChange={e => changeItem(e, category.value)} checked={checked}/>*/}
            <div className={checked ? 'filter-checkbox clicked' : 'filter-checkbox'} ></div>
            {category.title}
        </label>
        <span className="count">({category.count})</span>
    </li>
  )
}

export default Checkbox