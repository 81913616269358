import React from "react";
import { NavLink } from "react-router-dom";
import { MAIN_PAGE_ROUTE } from "../../navigation/Constants";
import { useLangContext } from "../../i18n/ProvideLang";

const Breadcrumbs = ({title}) => {
    const {lang, localizationsItems} = useLangContext();

  return (
    <div id="sns_breadcrumbs" className="wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div id="sns_titlepage"></div>
                    <div id="sns_pathway" className="clearfix">
                        <div className="pathway-inner">
                            <span className="icon-pointer "></span>
                            <ul className="breadcrumbs">
                                <li className="home">
                                    <NavLink to={`/${lang}`}>
                                    <i className="fa fa-home"></i>
                                    <span>{localizationsItems?.home}</span>
                                    </NavLink>
                                </li>
                                <li className="category3 last">
                                    <span>{title}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Breadcrumbs;
