import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';

const Error = () => {
    const {localizationsItems} = useLangContext();
  return (
    <div id="sns_content" className="wrap layout-m">
        <div className="container">
            <div className="row">
                <div className="content">
                    <h1>404</h1>
                    <h2>{localizationsItems?.not_found}</h2>
                    <p>{localizationsItems?.not_found_content}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Error