import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import Article from '../../components/complex/Article'
import Breadcrumbs from '../../components/complex/Breadcrumbs'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/AppServices'
import { useLangContext } from '../../i18n/ProvideLang'

const SamplePage = () => {
    const {id} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2){
                navigate(`/${lang}/page/${id}`)
            }
        }
    }, [lang])

    const { 
        items, 
    } = useFetchItems(AppServices.page, id);

  return (
    <>
        <Helmet>
            <title>{items?.title}</title>
        </Helmet>
        <Breadcrumbs title={items?.title}/>
        {items ? 
          <div className="blog-pagev1 detail cms-simen-home-page-v2 default cmspage">
              <Article item={items}/>
          </div>
          :
          <div className="preloader">
            <div className="isLoading"></div>
          </div>
        }
    </>
  )
}

export default SamplePage