import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './navigation/AppRouter';
import { Suspense } from 'react';
import './i18n/i18n'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./App.scss"
import ScrollToTop from './hooks/scrollToTop';
import ProvideLang from "./i18n/ProvideLang";

const App = () => {
  return (
    <Suspense fallback={null}>
        <ProvideLang>
            <BrowserRouter>
                <ScrollToTop />
                <AppRouter/>
            </BrowserRouter>
        </ProvideLang>
    </Suspense>
  )
}

export default App