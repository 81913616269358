import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Advantages from '../../components/complex/Advantages'
import Breadcrumbs from '../../components/complex/Breadcrumbs'
import NewsGrid2 from '../../components/complex/NewsGrid2'
import Partners from '../../components/complex/Partners'
import PointsBlock from '../../components/complex/PointsBlock'
import ProjectsGrid from '../../components/complex/ProjectsGrid'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/AppServices'
import Description from '../../components/simple/Description'
import { useNavigate, useParams } from 'react-router-dom'
import { useLangContext } from '../../i18n/ProvideLang'

const Projects = () => {
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/projects`)  
            }
        }
    }, [lang])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(AppServices.page, 'projects')

  return (
    <>
        <Helmet>
            <title>{items?.title}</title>
        </Helmet>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
        :
            <div>
                <Breadcrumbs title={items?.title}/>
                <ProjectsGrid/>
                {items.elements?.map((elem, index) => 
                <div key={index}>
                    {
                    elem.type === "advantages" ?
                        <Advantages item={elem}/>
                    :
                    elem.type === "partners" ?
                        <Partners item={elem}/>
                    :
                    elem.type === "articles" ?
                        <NewsGrid2 item={elem}/>
                    :
                    elem.type === "steps" ?
                        <PointsBlock item={elem}/>
                    :
                    elem.type === "text-information" ?
                        <Description item = {elem}/>
                    :
                    <></>
                    }
                </div>
                )}
            </div>
        }
    </>
  )
}

export default Projects