import React, { useEffect } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import ArticleServices from '../../services/general/ArticleServices';
import NewsCard2 from "../../components/simple/NewsCard2";
import Breadcrumbs from "../../components/complex/Breadcrumbs";
import Pagination from '../../components/complex/Pagination';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AppServices from '../../services/general/AppServices';
import ArticlesGrid from '../../components/complex/ArticlesGrid';
import { useLangContext } from '../../i18n/ProvideLang';

const Articles = () => {
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/articles`)
            }
        }
    }, [lang])

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(AppServices.page, 'articles')

  return (
    <>
        <Helmet>
            <title>{items?.title}</title>
        </Helmet>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
        :
            <div>
                <Breadcrumbs title={items.title}/>
                <ArticlesGrid/>
            </div>
        }

    </>
  )
}

export default Articles