import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Advantages from "../../components/complex/Advantages";
import MainOffer from "../../components/complex/MainOffer";
import NewsGrid from "../../components/complex/NewsGrid";
import NewsGrid2 from "../../components/complex/NewsGrid2";
import Partners from "../../components/complex/Partners";
import PointsBlock from "../../components/complex/PointsBlock";
import useFetchItems from "../../hooks/useFetchItems";
import AppServices from "../../services/general/AppServices";
import Description from "../../components/simple/Description";
import MainProducts from "../../components/complex/MainProducts";
import { useNavigate, useParams } from "react-router-dom";
import { useLangContext } from "../../i18n/ProvideLang";

const MainPage = () => {
    const navigate = useNavigate();
    const {lang} = useLangContext();
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2){
                navigate(`/${lang}`);
            }
        }
    }, [lang])

    const { 
        items, 
        loading,
    } = useFetchItems(AppServices.page, "main");

  return (
    <>
      {items ?
          <>
            <Helmet>
              <title>{items.title}</title>
            </Helmet>
            {items.elements?.map((elem, index) => 
              <div key={index}>
                {elem.type === "opening" ?
                        <MainOffer item={elem} />
                    :
                    elem.type === "advantages" ?
                        <Advantages item={elem}/>
                    :
                    elem.type === "projects" ?
                        <NewsGrid item={elem}/>
                    :
                    elem.type === "partners" ?
                        <Partners item={elem}/>
                    :
                    elem.type === "articles" ?
                        <NewsGrid2 item={elem}/>
                    :
                    elem.type === "steps" ?
                        <PointsBlock item={elem}/>
                    :
                    elem.type === "text-information" ?
                        <Description item={elem}/>
                    :
                    elem.type === "products" ?
                        <MainProducts item={elem}/>
                    :
                  <></>
                }
              </div>
            )}
          </>
        :
        <div className="preloader">
            <div className="isLoading"></div>
        </div>
        }
    </>
  );
};

export default MainPage;