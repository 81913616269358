import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import MobMenuSub from './MobMenuSub';

const MobMenuHeader = ({item, active, setActive}) => {
    const [show1, setShow1]= useState(true);
    
  return (
    <li className="level0 nav-6 parent">
        <div className="accr_header">
            <NavLink className=" menu-title-lv0" to={item.value} onClick={() => setActive(false)}>
                <span>{item.title}</span>
            </NavLink>
            {item.items && 
                <span className="btn_accor" onClick={() => setShow1(!show1)}>
                    <i className={show1 ? "fa fa-minus" : "fa fa-plus"}></i>
                </span>
            }
        </div>
        <div className="accr_content" style={{display: show1 ? "block" : "none"}}>
            <ul className="level0">
                {item.items?.map((elem, index) => 
                    <MobMenuSub elem={elem} key={index} active={active} setActive={setActive}/>
                )}
            </ul>
        </div>
    </li>
  )
}

export default MobMenuHeader