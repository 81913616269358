import React from 'react'
import NewsCard2 from '../simple/NewsCard2'
import OwlCarousel from 'react-owl-carousel';
import ArticleServices from '../../services/general/ArticleServices';
import useFetchItems from '../../hooks/useFetchItems';
import { useNavigate } from 'react-router-dom';
import { useLangContext } from '../../i18n/ProvideLang';

const NewsGrid2 = ({item, title}) => {
    const navigate = useNavigate();
    const {lang} = useLangContext();

    const { 
        items, 
        loading, 
        pageCount, 
        params, 
        handlePageClick, 
        setFilterData 
    } = useFetchItems(ArticleServices.index);

    const options = {
        nav: true,
        dots: false,
        loop: false,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }
    }

  return (
    items?.items?.length >= 1 &&
    <div className='container'>
        <div className="sns-latestblog sns-latestblog2">
            <div className="row">
                <div className="col-md-12">
                    <div className="block-title">
                        <h3>{title ? title : item?.title}</h3>
                    </div>
                </div>
                    <OwlCarousel
                        id="latestblog132"
                        className="latestblog-content owl-carousel owl-theme"
                        style={{display: "inline-block"}}
                        {...options}
                    >
                        {items.items.map((item, index) =>
                            <NewsCard2 item={item} key={index} onClick={() => {navigate(`/${lang}/articles/${item.slug}`)}}/>
                        )}
                    </OwlCarousel>
            </div>
        </div>
    </div>
  )
}

export default NewsGrid2