import React from 'react'

const PointsBlock = ({item}) => {
  return (
    item?.elements.length > 0 ?
    <div className='point-blocks'>
        <div className="container mr-b-50">
            <div className="row">
                <div className="col-md-12 col-main">
                    <h3>{item?.title}</h3>
                    <div className='row'>
                        {item?.elements.map((point, index) => 
                            <div className='col-md-4 col-sm-6 col-xs-12' key={index}>
                                <div className='block-title'>
                                    <span>{index+1}</span>
                                    <div>
                                        {point.title}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div> : <></>
  )
}

export default PointsBlock