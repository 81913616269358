import React from "react";
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from "react-router-dom";
import useFetchItems from "../../hooks/useFetchItems";
import ProjectServices from "../../services/general/ProjectServices";
import NewsCard from "../simple/NewsCard";
import { useLangContext } from "../../i18n/ProvideLang";

const NewsGrid = ({item, title}) => {
  const navigate = useNavigate();
  const {lang} = useLangContext();

  const { 
    items, 
    loading, 
    pageCount, 
    params, 
    handlePageClick, 
    setFilterData 
  } = useFetchItems(ProjectServices.index);

    const options = {
        nav: true,
        dots: false,
        loop: false,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }
    }

  return (
    items?.items?.length >= 1 &&
    <div className="container">
      <div className="sns-latestblog">
        <div className="row">
          <div className="col-md-12">
            <div className="block-title">
              <h3>{title ? title : item?.title}</h3>
            </div>
          </div>
            <OwlCarousel
                id="latestblog132"
                className="latestblog-content owl-carousel owl-theme"
                style={{display: "inline-block"}}
                {...options}
            >
              {items.items.map((item, index) => 
                  <NewsCard item={item} key={index} onClick={() => {navigate(`/${lang}/projects/${item.slug}`)}}/>
              )}
            </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default NewsGrid;
