import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Advantages from "../../components/complex/Advantages";
import BannerCategory from "../../components/complex/BannerCategory";
import CategoryGrid from "../../components/complex/CategoryGrid";
import NewsGrid from "../../components/complex/NewsGrid";
import NewsGrid2 from "../../components/complex/NewsGrid2";
import Partners from "../../components/complex/Partners";
import PointsBlock from "../../components/complex/PointsBlock";
import useFetchItems from "../../hooks/useFetchItems";
import CatalogServices from "../../services/general/CatalogServices";
import Description from "../../components/simple/Description";
import { useLangContext } from "../../i18n/ProvideLang";
import MainProducts from "../../components/complex/MainProducts";

const CategoryPage = () => {
    const {id} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();
  
    useEffect(() => {
      if(lang){
          if(lang !== lang2) {
              navigate(`/${lang}/catalog/${id}`)
          }
      }
    }, [lang])
  
    const { 
      items, 
    } = useFetchItems(CatalogServices.categories.view, id);

  return (
    <>
      {items ?
        <>
            <Helmet>
                <title>{items.title}</title>
            </Helmet>
            <BannerCategory item={items}/>
            {items.elements?.map((elem, index) => 
              <div key={index}>
                {
                    elem.type === "advantages" ?
                        <Advantages item={elem}/>
                    :
                    elem.type === "projects" ?
                        <NewsGrid item={elem}/>
                    :
                    elem.type === "partners" ?
                        <Partners item={elem}/>
                    :
                    elem.type === "articles" ?
                        <NewsGrid2 item={elem}/>
                    :
                    elem.type === "steps" ?
                        <PointsBlock item={elem}/>
                    :
                    elem.type === "text-information" ?
                        <Description item = {elem}/>
                    :
                    elem.type === "filter-1" ?
                        <CategoryGrid item={items.elements?.find(it => it.type === "filter")} categoryId={items.id} title={items.title}/>
                    :
                    elem.type === "products" ?
                        <MainProducts item={elem}/>
                    :
                  <></>
                }
              </div>
            )}
        </>
      :
      <div className="isLoading"></div>
      }
    </>
  );
};

export default CategoryPage;