import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import AppServices from '../../services/general/AppServices';
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';

const ModalCustom = ({show, handleClose, productName}) => {
  const {handleSubmit, register, reset, control, formState: {errors}} = useForm();
    const [loading, setLoading] = useState();
    const [visible, setVisible] = useState();
    const {localizationsItems} = useLangContext();
    const [points, setPoints] = useState();
    
    function getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const {
        items,
    } = useFetchItems(AppServices.settings)

    useEffect(() => {
        if(items){
            setPoints(items.find(it => it.type === "form-modal-inputs")?.value)
        }
    }, [items])

    const onSubmit = async (data) => {
        let query = new URLSearchParams(window.location.search);

        const {name, phone, ...rest} = data;
        
        let newData = {}
        if(productName){
            newData = {
                fields: {
                    Имя: name, 
                    Номер: phone,
                    Товар: productName
                }
            }
        }else if(points){
            newData = {
                fields: {
                    ...rest
                }
            }
        }else{
            newData = {
                fields: {
                    Имя: name, 
                    Номер: phone,
                }
            }
        }

        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            getCookie('utm_source') && newData.append('utm_source', getCookie('utm_source'));
            getCookie('utm_medium') && newData.append('utm_medium', getCookie('utm_medium'));
            getCookie('utm_campaign') && newData.append('utm_campaign', getCookie('utm_campaign'));
            getCookie('utm_content') && newData.append('utm_content', getCookie('utm_content'));
            getCookie('utm_term') && newData.append('utm_term', getCookie('utm_term'));
        }

        setLoading(true);
        
        const res = await AppServices.requestTemplate(newData);

        if (res.statusCode === 200) {
            setVisible(true);

            setTimeout(() => {
                setVisible(false)
                reset();
                handleClose();
            }, 3000);
        }

        setLoading(false);
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{localizationsItems?.leave_request}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form className='modal-form' onSubmit={handleSubmit(onSubmit)}>
                {(points && !productName) ?
                    <>
                        {points.map((point, index) =>
                            <input key={index} type="text" name={point} placeholder={point} {...register(point)} required/>
                        )}
                    </>
                    :
                    <>
                        <input type="text" name='name' placeholder={localizationsItems?.name} {...register('name')} required/>
                        <Controller name="phone" control={control} defaultValue=""
                              render={({field: {onChange, value}}) => (
                                <ReactInputMask  mask="+7(999)999-99-99" maskChar="_"
                                                onChange={onChange} value={value}
                                >
                                    {inputProps => (
                                        <input {...inputProps} type="tel" required className="input-text required-entry validate-email" placeholder={localizationsItems?.phone}
                                          />
                                    )}
                                </ReactInputMask>
                              )}
                              rules={{required: true, pattern: {
                                value: /(\+7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})/g
                              }}}
                            />
                            {errors.phone && errors.phone.type === 'required' &&
                            <div className="error-text">{localizationsItems?.required_field}</div>}
                            {errors.phone && errors.phone.type === 'pattern' &&
                            <div className="error-text">{localizationsItems?.incorrect_value}</div>}
                    </>
                }
                <button type='submit' className="modal-black-button">
                    {localizationsItems?.leave_request}
                </button>
            </form>
        </Modal.Body>
        <Modal.Footer>
          <div className='text'>
            {localizationsItems?.callback_you}
          </div>
        </Modal.Footer>
        <div className={visible ? 'modal-success modal-success-visible' : 'modal-success'}>
          <i className="fa fa-check"></i>
          <h4>{localizationsItems?.application_thanks}</h4>
          <div>
          {localizationsItems?.application_content}
          </div>
        </div>
    </Modal>
  )
}

export default ModalCustom