import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import Footer from '../components/complex/Footer';
import Header from '../components/complex/Header';
import useFetchItems from '../hooks/useFetchItems';
import AppServices from '../services/general/AppServices';
import { ERROR_PAGE_ROUTE, MAIN_PAGE_ROUTE } from './Constants';
import { publicRoutes } from './Routes';

const AppRouter = () => {
  const { 
    items,
    pageCount, 
    params, 
    handlePageClick, 
    setFilterData 
  } = useFetchItems(AppServices.settings);

  useEffect(() => {
    if(items){
        let color = items.find(it => it.type === "color-primary").value;
        let secondColor = items.find(it => it.type === "secondary-color").value;

        let head = items.find(it => it.type === "head")?.value;
        let bottom = items.find(it => it.type === "bottom")?.value;
        let header = items.find(it => it.type === "header")?.value;

        if(head){
            head.forEach(element => {
                const divFragment =  document.createRange().createContextualFragment(element)
                document.head.append(divFragment);
            });
        }

        if(header){
            header.forEach(element => {
            const divFragment =  document.createRange().createContextualFragment(element)
            document.body.append(divFragment);
            });
        }

        if(bottom){
            bottom.forEach(element => {
            const divFragment =  document.createRange().createContextualFragment(element)
            document.body.prepend(divFragment);
            });
        }

        if(color){
            document.documentElement.style.setProperty('--mainColor', color)
        }
        secondColor && document.documentElement.style.setProperty('--secondaryColor', secondColor);      
    }
  }, [items])

  return (
    <Routes>
        {publicRoutes.map(({path, Component}) => 
          <Route key={path} path={path} element={
            <div id="sns_wrapper">
              <Header item={items}/>
              <Component></Component>
              <Footer item={items}/>
            </div>
          } exact/>
        )}
        <Route
            path="*"
            exact
            element={<Navigate to={ERROR_PAGE_ROUTE}/>}
        />
    </Routes>
  )
}

export default AppRouter