import React, { useState } from 'react'
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Utils from '../../services/utils';

const Video = ({banner2}) => {
    const [show, setShow] = useState();
    const [isUrl, setIsUrl] = useState();
    const [isVideo, setIsVideo] = useState();

    useEffect(() => {
      if(banner2.link){
          if(Utils.checkurl(banner2.link)){
              setIsUrl(Utils.youtube_parser(banner2.link))
          }else{
              setIsVideo(banner2.link)
          }
      }
  }, [banner2])

    const handleClose = () => setShow(false);
    

  return (
    <>
      <div className='banner-video' onClick={() => setShow(true)}>
          {isVideo && 
            <video>
                <source src={banner2.link} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>
          }
          {isUrl && 
            <img src={`https://i.ytimg.com/vi/${isUrl}/maxresdefault.jpg`}></img>
          }
          <div className={'span-active'}>
            <i className={'fa fa-play'}></i>
          </div>
          <div className={'video-text'}>
            <div>{banner2.title}</div>
            <p>{banner2.subtitle}</p>
          </div>
      </div>
      <Modal show={show} onHide={handleClose} centered className='modal-new'>
        <Modal.Body>
          <span className='fa fa-close' onClick={handleClose}></span>
          {isUrl && <iframe src={isUrl}></iframe>}
          {isVideo && <video controls>
              <source src={banner2.link} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
          </video>}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Video