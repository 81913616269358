import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import Article from '../../components/complex/Article';
import Breadcrumbs from '../../components/complex/Breadcrumbs';
import useFetchItems from '../../hooks/useFetchItems';
import ServiceServices from '../../services/general/ServiceServices';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import { useLangContext } from '../../i18n/ProvideLang';

const ServiceDetail = () => {
    const {id} = useParams();
    const {lang} = useLangContext();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}/services/${id}`)  
            }
        }
    }, [lang])

    const {info, loading} = useFetchInfo(ServiceServices.view, id);

  return (
    <>
        <Helmet>
            <title>{info?.title}</title>
        </Helmet>
        <Breadcrumbs title={info?.title}/>
        <div className="blog-pagev1 detail cms-simen-home-page-v2 default cmspage">
            {info ? 
              <Article item={info}/> 
              :
              <div className="isLoading"></div>
            }
        </div>
    </>
  )
}

export default ServiceDetail