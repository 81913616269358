import React from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/CatalogServices';
import { useNavigate } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import ProductCard from './ProductCard';

const MainProducts = ({item}) => {
    const navigate = useNavigate();

    const {lang} = useLangContext();

    const {
        items,
        loading,
        params, 
        setFilterData
    } = useFetchItems(CatalogServices.products.index, null, `type=${item.products_type}&`)

    const options2 = {
        nav: true,
        dots: false,
        loop: false,
        responsive: {
        0: {
            items: 1,
        },
        480: {
            items: 2,
        },
        768: {
            items: 3,
        },
        1200: {
            items: 4,
        },
        },
    };

  return (
    <div className="container">
        <div className="products-related related-padding">
            <div className="detai-products1">
                <div className="title">
                    <h3>{item?.title}</h3>
                </div>
                <div className="products-grid">
                    {items && (
                        <OwlCarousel
                        id="related_upsell1"
                        className="item-row owl-carousel owl-theme"
                        style={{ display: "inline-block" }}
                        {...options2}
                        >
                        {
                            items.items.map((item, index) => (
                            <div className="item" key={index} onClick={() =>navigate(`/${lang}/products/${item.slug}`)}>
                                <ProductCard item={item} />
                            </div>
                            ))}
                        </OwlCarousel>
                    )}
                </div>
            </div>
        </div>
    </div>
  )
}

export default MainProducts