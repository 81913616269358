import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CatalogServices from "../../services/general/CatalogServices";
import Filter from "./Filter";
import Pagination from "./Pagination";
import ProductCard from "./ProductCard";
import { useLangContext } from "../../i18n/ProvideLang";

const CategoryGrid = ({item, categoryId, title}) => {
    const navigate = useNavigate();
    const [items, setItems] = useState();
    const [loading, setLoading] = useState(true);
    const [pageCount, setPageCount] = useState();
    let query = new URLSearchParams(useLocation().search);
    const [params, setParams] = useState(query);
    const {lang} = useLangContext();
    const [categories, setCategories] = useState();

    useEffect(() => {
        setParams(query)
    }, [query.get('categories[]')])

    const fetchProducts = async () => {
        setLoading(true);
        
        const res = await CatalogServices.products.index(`category_id=${categoryId}&`+params.toString());

        navigate({search: params.toString()})

        if(res.statusCode === 200){
        setItems(res.content);
        setPageCount(res.content.total_pages);
        }

        setLoading(false)
    }

    useEffect(() => {
        fetchProducts();
    }, [categoryId, params])

    const handlePageClick = (data) => {
        query.set('page', data.selected + 1);
        setParams(query);
    };

    const setFilterData = (filterString) => {
        let newParams = new URLSearchParams(filterString);
        if (query.get('order')) {
            newParams.set('order', query.get('order'))
        }
        setParams(newParams);
    };

    useEffect(() => {
        if(item){
            setCategories(item.elements?.find(item => item.type == 'categories'));
        }
    }, [item])

  return (
    items?.items ?
      <div id="sns_content" className="wrap layout-lm">
        <div className="container">
          <div className="row">
            <div id="sns_left" className="col-md-3">
              <div className="wrap-in">
                {categories?.elements?.length >= 1 && <Filter categories={categories} setCategories={setCategories} params={params} setFilterData={setFilterData} productId={categoryId} title={title}/>}
              </div>
            </div>
            <div id="sns_main" className={categories?.elements?.length >= 1 ? "col-md-9 col-sm-12 col-main" : "col-md-12 col-sm-12 col-main"}>
              <div id="sns_mainmidle">
                <div className="category-products">
                  <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                  <div className="sns-products-container clearfix">
                    <div className="products-grid row style_grid">
                      {items && items.items.map((item, index) => 
                        <div className="item col-lg-3 col-md-4 col-sm-4 col-xs-6 col-phone-12" key={index}>
                          <ProductCard item={item} onClick={() => navigate(`/${lang}/products/${item.slug}`)}/>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : <></>
  );
};

export default CategoryGrid;
